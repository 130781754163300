<template>
  <v-container fluid no-gutters>
    <B2cTabs/>

    <div class="titles mt-6">Preferences</div>
    <v-card outlined class="mb-8" :style="cardStyle">
      <v-card-text>
        <v-row>
          <v-col lg="4" md="4">
            <v-checkbox
              v-model="configurations.enable_fb_reschedule"
              class="mt-2"
              false-value="0"
              true-value="1"
              label="Enable Facility Booking Reschedule"
            ></v-checkbox>
          </v-col>
          <v-col
            lg="4"
            md="4"
            v-if="checkReadPermission($modules.trainers.dashboard.slug)"
          >
            <v-checkbox
              v-model="configurations.enable_trainer_reschedule"
              class="mt-2"
              false-value="0"
              true-value="1"
              label="Enable Trainer Booking Reschedule"
            ></v-checkbox>
          </v-col>
          <v-col
            lg="4"
            md="4"
            v-if="checkReadPermission($modules.workshops.management.slug)"
          >
            <v-checkbox
              v-model="configurations.enable_academy_reschedule"
              class="mt-2"
              false-value="0"
              true-value="1"
              label="Enable Academy Booking Reschedule"
            ></v-checkbox>
          </v-col>
          <v-col
              lg="4"
              md="4"
          >
            <v-checkbox
                v-model="configurations.enable_facility_booking"
                class="mt-2"
                false-value="0"
                true-value="1"
                label="Enable Facility Booking"
            ></v-checkbox>
          </v-col>
          <v-col
            lg="4"
            md="4"
            v-if="
              checkReadPermission($modules.b2cconfig.fBParticipantDetails.slug)
            "
          >
            <v-checkbox
              v-model="configurations.enable_fb_participant_details"
              class="mt-2"
              false-value="0"
              true-value="1"
              label="Facility Booking Participant Details"
            ></v-checkbox>
          </v-col>

          <v-col lg="4" md="4">
            <v-checkbox
              v-model="configurations.show_time_range_filter"
              class="mt-2"
              false-value="0"
              true-value="1"
              label="Show Time Range Filter"
            ></v-checkbox>
          </v-col>
          <v-col lg="4" md="4">
            <v-checkbox
              v-model="configurations.show_facility_image"
              class="mt-2"
              false-value="0"
              true-value="1"
              label="Show Facility Image on Booking Popup"
            ></v-checkbox>
          </v-col>
          <v-col lg="4" md="4">
            <v-checkbox
              v-model="configurations.hide_qube_membership_price"
              class="mt-2"
              false-value="0"
              true-value="1"
              label="Disable Membership Price on Qube"
            ></v-checkbox>
          </v-col>
          <v-col lg="4" md="4">
            <v-checkbox
              v-model="configurations.hide_venue_services_on_homepage"
              class="mt-2"
              false-value="0"
              true-value="1"
              label="Hide Venue Services"
            ></v-checkbox>
          </v-col>
          <v-col lg="4" md="4">
            <v-checkbox
                v-model="configurations.enable_qpoints_website"
                class="mt-2"
                false-value="0"
                true-value="1"
                label="Enable QPoints"
            ></v-checkbox>
          </v-col>
          <v-col lg="4" md="4">
            <v-checkbox
                v-model="configurations.enable_newsletter"
                class="mt-2"
                :false-value="0"
                :true-value="1"
                label="Enable Newsletter"
            ></v-checkbox>
          </v-col>
          <v-col lg="4" md="4" v-if="checkWritePermission($modules.b2cconfig.cartReservation.slug)">
            <v-checkbox
                v-model="configurations.allow_facility_items_reservation"
                class="mt-2"
                :false-value="0"
                :true-value="1"
                label="Enable Cart Reservation"
            ></v-checkbox>
          </v-col>
          <v-col lg="4" md="4" v-if="checkWritePermission($modules.b2cconfig.customDuration.slug)">
            <v-switch
                v-model="configurations.follow_venue_service_duration"
                class="mt-2"
                label="Follow Venue Service Duration"
            ></v-switch>
          </v-col>
          <v-col lg="4" md="4" v-if="!configurations.follow_venue_service_duration && checkWritePermission($modules.b2cconfig.customDuration.slug)">
            <label for="">Duration Increment</label>
            <v-text-field
                v-model="configurations.custom_duration"
                class="q-text-field shadow-0"
                outlined
                dense
                :rules="[
                  (v) => !!v || 'Duration Increment is required',
                  (v) => (v && v > 0) || 'Duration Increment should be greater than 0',
                ]"
            ></v-text-field>
          </v-col>
          <!-- <v-col
            lg="6"
            md="6"
            v-if="checkReadPermission($modules.events.management.slug)"
          >
            <v-checkbox
              v-model="configurations.enable_event_reschedule"
              class="mt-2"
              false-value="0"
              true-value="1"
              label="Enable Event Booking Reschedule"
            ></v-checkbox>
          </v-col> -->
        </v-row>
        <v-row
          v-if="
            checkReadPermission($modules.b2cconfig.timeCapacityFilter.slug) &&
            have_both_time_capacity_services
          "
        >
          <v-col lg="4" md="4">
            <v-checkbox
              v-model="configurations.enable_time_capacity_filter"
              class="mt-2"
              false-value="0"
              true-value="1"
              label="Facility Time/Capacity Filter"
            ></v-checkbox>
          </v-col>
          <v-col
            lg="4"
            md="4"
            v-if="configurations.enable_time_capacity_filter === '1'"
          >
            <v-text-field
              v-model="configurations.capacity_filter_title"
              class="mt-2"
              false-value="0"
              true-value="1"
              label="Capacity Filter Title"
              outlined
              required
            ></v-text-field>
          </v-col>
          <v-col
            lg="4"
            md="4"
            v-if="configurations.enable_time_capacity_filter === '1'"
          >
            <v-text-field
              v-model="configurations.time_filter_title"
              class="mt-2"
              false-value="0"
              true-value="1"
              label="Time Filter Title"
              outlined
              required
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>


    <div  class="text-base font-semibold black-text ml-1 mt-6">User Registration Configurations</div>
    <v-card class="mb-8 rounded-2 bordered shadow-0 mt-2" >
      <v-card-text>
        <v-row dense>
          <v-col cols="12">
            <table class="table text-center table-bordered">
              <thead>
              <tr class="opacity-70 tr-neon tr-rounded text-center font-bold black-text">
                <th class="text-center">Field Name</th>
                <th class="text-center">View</th>
                <th class="text-center">Mandatory</th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="(config, index) in configurations.field_configurations"
                  :key="index"
              >
                <td class="black-text font-bold text-sm  " style="font-weight: 500 !important;">{{ config.name }}</td>
                <td>
                  <v-btn
                      icon
                      :style="
                          viewRestrictedFields.includes(config.slug) &&
                            'cursor:not-allowed;'
                        "
                      @click="checkAsVisible(index)"
                  >
                    <TickIcon v-if="config.is_visible == 1 "/>
                    <CloseIcon v-else/>

                  </v-btn>
                </td>
                <td>
                  <v-btn
                      icon
                      @click="checkAsMandatory(index)"
                      :style="
                          mandatoryRestrictedFields.includes(config.slug) &&
                            'cursor:not-allowed;'
                        "
                  >
                    <TickIcon v-if="config.is_required == 1 "/>
                    <CloseIcon v-else/>

                  </v-btn>
                </td>
              </tr>
              </tbody>
            </table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>


    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        class="ma-2 white--text teal-color"
        text
        height="45"
        @click="submitData()"
        >Save</v-btn
      >
    </v-card-actions>
  </v-container>
</template>
<script>
import B2cTabs from "@/views/B2CConfigurations/B2cTabs.vue";
import TickIcon from "@/assets/images/misc/config-table-tick-icon.svg";
import CloseIcon from "@/assets/images/misc/config-table-close-icon.svg";
import constants from "@/utils/constants";

export default {
  components: {CloseIcon, TickIcon, B2cTabs},
  mounted() {
    if (this.$store.getters.getVenueServices.status == false) {
      this.$store.dispatch("loadVenueServices");
    }
    this.btnShow = true;
    this.getConfigurations();
  },
  computed: {
    venueServices() {
      return this.$store.getters.getVenueServices.data.filter(
        (service) => service.name != "POS"
      );
    },
  },
  data() {
    return {
      btnShow: false,
      defaultColors: {
        app_colors: {
          primary: "#007FFF",
        },
      },
      viewRestrictedFields: constants.VIEWRESTRICTEDFIELDS,
      mandatoryRestrictedFields: constants.MANTATORYRESTRICTEDFIELDS,
      dialogPrimary: false,
      configurations: {
        field_configurations: [],
        enable_fb_reschedule: "0",
        enable_trainer_reschedule: "0",
        enable_academy_reschedule: "0",
        enable_facility_booking: "0",
        enable_event_reschedule: "0",
        enable_fb_participant_details: "0",
        show_time_range_filter: "0",
        show_facility_image: "0",
        enable_time_capacity_filter: "0",
        hide_qube_membership_price: "0",
        hide_venue_services_on_homepage: "0",
        enable_qpoints_website: "0",
        enable_newsletter: 1,
        capacity_filter_title: null,
        time_filter_title: null,
        follow_venue_service_duration: true,
        custom_duration: null,
        allow_facility_items_reservation: 0,
      },
      have_both_time_capacity_services: true,
    };
  },

  methods: {
    checkAsMandatory(index) {
      let field = this.configurations.field_configurations[index];
      if (!this.mandatoryRestrictedFields.includes(field.slug)) {
        this.configurations.field_configurations[index].is_required =
            field.is_required == 1 ? 0 : 1;
        if (field.is_required == 1 && field.is_visible == 0) {
          this.configurations.field_configurations[index].is_visible = 1;
        }
      }
    },
    checkAsVisible(index) {
      let field = this.configurations.field_configurations[index];
      if (!this.viewRestrictedFields.includes(field.slug)) {
        this.configurations.field_configurations[index].is_visible =
            field.is_visible == 1 ? 0 : 1;
        if (field.is_required == 1 && field.is_visible == 0) {
          this.configurations.field_configurations[index].is_required = 0;
        }
      }
    },
    getConfigurations() {
      this.showLoader("Loading");

      this.$http
        .get("venues/b2c/bookings-configurations")
        .then((response) => {
          if (response.status == 200) {
            let data = response.data.data;

            if (data.enable_fb_reschedule) {
              this.configurations.enable_fb_reschedule =
                data.enable_fb_reschedule.toString();
            } else {
              this.configurations.enable_fb_reschedule = "0";
            }
            if (data.enable_trainer_reschedule) {
              this.configurations.enable_trainer_reschedule =
                data.enable_trainer_reschedule.toString();
            } else {
              this.configurations.enable_trainer_reschedule = "0";
            }
            if (data.enable_academy_reschedule) {
              this.configurations.enable_academy_reschedule =
                data.enable_academy_reschedule.toString();
            } else {
              this.configurations.enable_academy_reschedule = "0";
            }
            if (data.enable_facility_booking) {
              this.configurations.enable_facility_booking =
                data.enable_facility_booking.toString();
            } else {
              this.configurations.enable_facility_booking = "0";
            }
            if (data.enable_event_reschedule) {
              this.configurations.enable_event_reschedule =
                data.enable_event_reschedule.toString();
            } else {
              this.configurations.enable_event_reschedule = "0";
            }
            if (data.enable_fb_participant_details) {
              this.configurations.enable_fb_participant_details =
                data.enable_fb_participant_details.toString();
            } else {
              this.configurations.enable_fb_participant_details = "0";
            }
            if (data.show_facility_image) {
              this.configurations.show_facility_image =
                data.show_facility_image.toString();
            } else {
              this.configurations.show_facility_image = "0";
            }
            if (data.show_time_range_filter) {
              this.configurations.show_time_range_filter =
                data.show_time_range_filter.toString();
            } else {
              this.configurations.show_time_range_filter = "0";
            }
            if (data.enable_time_capacity_filter) {
              this.configurations.enable_time_capacity_filter =
                data.enable_time_capacity_filter.toString();
            } else {
              this.configurations.enable_time_capacity_filter = "0";
            }
            if (data.hide_qube_membership_price) {
              this.configurations.hide_qube_membership_price =
                data.hide_qube_membership_price.toString();
            } else {
              this.configurations.hide_qube_membership_price = "0";
            }
            if (data.hide_venue_services_on_homepage) {
              this.configurations.hide_venue_services_on_homepage =
                data.hide_venue_services_on_homepage.toString();
            } else {
              this.configurations.hide_venue_services_on_homepage = "0";
            }
            if (data.enable_qpoints_website) {
              this.configurations.enable_qpoints_website =
                data.enable_qpoints_website.toString();
            } else {
              this.configurations.enable_qpoints_website = "0";
            }
            this.configurations.enable_newsletter = data.enable_newsletter??1;
            this.configurations.allow_facility_items_reservation = data.allow_facility_items_reservation??0;
              this.configurations.follow_venue_service_duration = data.follow_venue_service_duration;
              this.configurations.custom_duration = data.custom_duration;
            this.configurations.capacity_filter_title =
              data.capacity_filter_title;
            this.configurations.time_filter_title = data.time_filter_title;

            this.have_both_time_capacity_services =
              response.data.have_both_time_capacity_services;

            this.configurations.field_configurations = data.field_configurations
            // this.configurations.enable_fb_reschedule =
            //   data.enable_fb_reschedule;
            // this.configurations.enable_trainer_reschedule =
            //   data.enable_trainer_reschedule;
            // this.configurations.enable_academy_reschedule =
            //   data.enable_academy_reschedule;
            // this.configurations.enable_event_reschedule =
            //   data.enable_event_reschedule;
            this.hideLoader();
          }
        })
        .catch((error) => {
          console.log(error);
          this.hideLoader();
        });
    },

    submitData() {
      this.showLoader("Loading");

      let data = this.configurations;

      this.$http
        .post(`venues/b2c/bookings-configurations`, data)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.showSuccess("Configuration saved successfully.");
            this.getConfigurations();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        }).finally(() => {
          this.hideLoader();
        });
    },
    resetColors() {
      this.configurations.app_colors = this.defaultColors.app_colors;
    },
  },
};
</script>

<style scoped>
.salesBtn {
  padding: 5px;
  font-size: 14px;
  font-weight: bold;
  color: #066a8c;
}
.btn_bg {
  background-color: #fff;
  border-bottom: 3px solid #066a8c;
  border-radius: 10px;
  box-shadow: 0 3px 3px -2px black;
}
</style>
